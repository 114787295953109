@import '../../../../style/variables';

.stakeUnit {
	background-color: darken($woodlandBlue, 5%);
	padding: 32px 32px 64px 32px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (max-width: $tablet) {
		padding: 0 8px 32px 8px;
	}

	h1 {
		color: $white-ter;
		font-size: 3rem;
		text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
		width: 100%;
		text-align: center;

		&:before, &:after {
			width: 50px;
			display: inline-flex;
			align-items: center;
			border-bottom: 2px solid $grey-lightest;
			content: '';
			height: 20px;
			position: relative;
			top: -22px;
			margin: 0 16px;
		}

		&:before {
			margin-left: 0;
		}

		&:after {
			margin-right: 0;
		}

		@media screen and (max-width: $tablet) {
			&:before, &:after {
				width: 25px;
			}
		}
	}

	> p {
		font-size: 1.2rem;
		color: $white-bis;

		@media screen and (max-width: $desktop) {
			max-width: 100%;
			padding: 8px;
		}

		@media screen and (min-width: $desktop) {
			max-width: 70%;
			padding: 8px;
		}

		@media screen and (min-width: $fullhd) {
			width: 60%;
			padding: 8px;
		}
	}

	.stakingGuideContainer {
		padding: 16px;
		background-color: $white-bis;
		border-radius: $borderRadius;
		box-shadow: 0 3px 3px 1px rgba(0, 0, 0, 0.1);
		margin-top: 48px;

		@media screen and (max-width: $desktop) {
			width: 100%;
			margin: 0;
		}

		@media screen and (min-width: $desktop) {
			width: 70%;
			padding: 8px;
		}

		@media screen and (min-width: $fullhd) {
			width: 60%;
			padding: 8px;
		}

		> h2 {
			font-size: 2rem;
			text-align: center;
		}

		.stakingGuideColumn {
			min-height: 200px;

			p, h2 {
				color: $textColor;
			}

			h2 {
				color: $woodlandBrown;
				font-size: 2rem;
				margin-bottom: 16px;
			}
		}

		.stakingGuideColumn > div {
			padding: 0 32px 32px 32px;

			@media screen and (max-width: $tablet) {
				padding: 0 16px 16px 16px;
			}
		}

		.stakingGuideColumn:first-child, .stakingGuideColumn:nth-child(3) {
			border-right: 1px solid transparentize($grey-lighter, 0.2);

			@media screen and (max-width: $tablet) {
				border-right: none;
				border-bottom: 1px solid transparentize($grey-lighter, 0.2);
			}
		}

		.stakingGuideColumn:first-child, .stakingGuideColumn:nth-child(2) {
			border-bottom: 1px solid transparentize($grey-lighter, 0.2);
		}

		strong {
			color: $woodlandBrown;
		}

		pre {
			padding: 4px;
			margin-top: 4px;
			white-space: normal;
			word-break: break-all;
		}
	}
}
