@import '../../style/common';

$toolsHeight: calc(100vh - #{$headerHeight});

.Tools {
	background-color: $white;

	@media screen and (max-width: $tablet) {
		overflow-y: auto;
	}

	.navColumn {
		z-index: 2;
	}

	.navInner {
		background-color: $white-bis;
		border-right: 1px solid $grey-lighter;
		height: $toolsHeight;
		position: relative;

		@media screen and (max-width: $tablet) {
			height: auto;
		}

		.navHeader {
			background-color: saturate(darken($woodlandBlue, 17%), 5%);
			border-top: 1px solid darken($woodlandBlue, 15%);
			padding: 0 24px;
			position: relative;
			top: 12px;
			width: calc(100% + 1px);
			box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
			height: 51px;
			display: flex;
			align-items: center;

			@media screen and (max-width: $tablet) {
				height: 30px;
				margin-bottom: 8px;
			}

			h2 {
				margin: 0;
				color: $white;
				position: relative;
				top: -2px;
			}
		}

		nav {
			position: relative;
			top: 32px;

			@media screen and (max-width: $tablet) {
				top: 0;
			}

			ul {
				list-style-type: none;
			}

			li {
				a {
					display: block;
					font-family: Inter, sans-serif;
					color: $woodlandBrown;
					cursor: pointer;
					transition: all 50ms ease-in-out;
					font-weight: 500;
					padding: 12px 24px;
					width: calc(100% + 1px);

					&:active, &:visited {
						color: $woodlandBrown;
					}

					&:hover {
						color: $white-bis;
						background-color: saturate($woodlandBlue, 10%);

						&:before {
							color: $white-bis;
						}
					}
				}
			}
		}
	}

	.toolsLanding {
		p {
			min-height: 90px;
		}
	}

	.toolContent {
		height: $toolsHeight;
		width: 100%;
		overflow-y: auto;
		background-color: $white;

		@media screen and (max-width: $tablet) {
			overflow: unset;
		}

		.toolHeader {
			height: 50px;
			position: relative;
			top: 12px;

			@media screen and (max-width: $tablet) {
				height: 30px;

				h1 {
					height: 30px;
					font-size: 1.8rem;
					display: flex;
					align-items: center;
				}
			}

			.toolHeaderInner {
				width: 100%;
				background-color: saturate(darken($woodlandBlue, 18%), 15%);
				border-top: 1px solid darken($woodlandBlue, 22%);
				padding: 0 24px;
				box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1), inset 4px 0 2px rgba(0, 0, 0, 0.1);
				display: flex;
				align-items: center;

				h1 {
					height: 50px;
					margin: 0;
					color: $grey-lightest;
					text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
					position: relative;
					top: -3px;
				}
			}
		}

		.toolContentInner {
			padding: 70px 58px 58px 58px;

			@media screen and (max-width: $tablet) {
				padding: 70px 12px;
			}
		}
	}
}
