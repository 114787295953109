@import '../../../style/variables';
@import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

.epochRewardsEstimator {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 80%;

	@media screen and (max-width: $tablet) {
		max-width: 100%;
	}

	h2 {
		margin-top: 0;
	}

	section + section {
		margin-top: 20px;
	}

	.informationSection {
		.disclaimer {
			border-left: 3px solid $orange;
			background-color: $grey-lightest;
			padding: 4px 4px 4px 8px;
			border-radius: $borderRadius;
			font-size: 0.9rem;
		}
	}

	.inputSection {
		background-color: $grey-lightest;
		padding: 12px;
		border-radius: $borderRadius;
		margin-top: 24px;
		width: 100%;

		@media screen and (max-width: $tablet) {
			padding: 10px;
		}

		h4 {
			font-size: 1rem;
			line-height: 14px;
			font-weight: bold;
		}

		small {
			display: block;
			margin: 4px 0 6px 0;
		}

		button {
			display: inline-block;
		}

		label {
			font-weight: unset;
		}

		input.poolSearchBox {
			width: 220px;

			@media screen and (min-width: $phone) {
				width: 250px;
			}
		}

		section.stakeInput {
			input {
				max-width: 300px;
			}

			i.icon {
				margin-top: -2px;
				margin-left: -2px;
				font-style: normal;
				font-size: 1.1rem;
				color: $grey-light;
			}
		}
	}

	.resultsSection {
		$rowHeight: 80px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 32px;

		.placeholder {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-top: 64px;
			opacity: 0.75;

			.placeholderIcon {
				text-align: center;
				font-size: 5rem;

				&.loading {
					.fa-spinner {
						animation: rotation 2s infinite linear;
					}
				}
			}

			.placeholderText {
				font-size: 1.5rem;
				user-select: none;
				-moz-user-select: none;
				-webkit-user-select: none;

				text-align: center;
			}
		}

		> section {
			width: 100%;
		}

		.feesTable {
			.card-content {
				background-color: $white;
				padding: 14px;
			}

			.column {
				padding: 4px 12px;
			}
		}

		.plus {
			color: $green;
		}

		.minus {
			color: $red;
		}

		div.calcSection {
			background-color: $white;
			margin-bottom: 12px;
			border: 1px solid $grey-lightest;
			border-radius: $borderRadius;
		}

		div.itemRow {
			min-height: $rowHeight;
			padding: 0;
			position: relative;

			@media screen and (max-width: $tablet) {
				min-height: calc(#{$rowHeight} + 10px);
			}

			div.columns {
				height: 100%;
			}

			&.totalRow {
				div.itemHeaderColumn {
					font-size: 1.25rem;
				}
			}

			&.totalRow, &.feesRow, &.totalRewardsRow {
				border-left: 3px solid $blue;
			}

			&.rewardsRow {
				border-left: 3px solid $purple;
			}

			&.yourRewardsRow {
				margin-top: 12px;
				background-color: darken($green, 15%);
				color: $white-ter !important;
				border: 1px solid transparentize($green, 0.7);
				border-radius: $borderRadius;
				border-left: 5px solid $green;
				min-height: 100px;
				height: unset;
				padding: 12px 0;

				div.itemHeaderColumn {
					font-size: 1.5rem;
					line-height: 20px;
				}

				div.itemHeaderColumn, div.itemValueColumn, div.itemExplanation {
					color: $white-ter;
				}

				strong {
					color: $white-ter;
				}
			}

			div.columns {
				margin: 0;
			}

			div.column {
				height: 100%;
			}

			div.itemHeaderColumn {
				color: $grey-darker;
				font-weight: bold;
				font-size: 1rem;
				line-height: 16px;
				opacity: 0.9;
				display: flex;
				flex-direction: column;

				padding: 18px 12px 12px 12px;
			}

			div.itemValueColumn {
				color: $grey-darker;
				font-size: 1.25rem;
				line-height: 18px;
				display: flex;
				flex-direction: column;

				padding: 18px 12px 12px 12px;

				div.sign {
					width: 20px;
					display: inline-block;
				}

				div.itemValue {

				}

				div.itemExplanation {
					position: relative;
					top: 10px;

					opacity: 0.8;
					font-size: 0.9rem;

					padding: 0 12px;
					margin-left: 8px;
				}
			}
		}
	}
}
