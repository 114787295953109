@import './variables.scss';

p {
	margin: 12px 0;
	font-size: 1rem;
	font-family: Inter, sans-serif;
	color: $textColor;
	font-weight: 300;
}

h1 {
	font-size: 2rem;
	font-weight: bold;
	margin-top: 8px;
	margin-bottom: 24px;
	font-family: Lato, sans-serif;
	font-variant: all-small-caps;
	color: $woodlandBrown;
	letter-spacing: 1px;
}

h2 {
	font-size: 1.5rem;
	font-weight: bold;
	margin-top: 8px;
	margin-bottom: 24px;
	font-family: Roboto, sans-serif;
	font-variant: all-small-caps;
	color: #5A6173;

	&.sectionHeader {
		&:before {
			content: '';
			display: inline-block;
			width: 5px;
			height: 14px;
			background-color: $headerColor;
			border-radius: 1px;
			position: relative;
			top: 1px;
			margin-right: 5px;
			opacity: 0.5;
			transition: opacity 300ms ease-in-out;
		}

		&:hover:before {
			opacity: 0.6;
		}
	}
}

h3 {
	font-weight: bold;
	margin-top: 8px;
	margin-bottom: 16px;
	color: transparentize($grey-dark, 0.3);
}

.externalLink {
	a, a:active, a:visited {
		color: $cyan;
	}

	a:hover, a:visited:hover {
		color: lighten($cyan, 12%);
	}

	.material-icons {
		font-size: 0.85rem;
		position: relative;
		top: 2px;
		margin-left: 2px;
		opacity: 0.8;
	}
}

button {
	a, a:visited, a:active {
		color: $blue;

		&:hover {
			color: $blue !important;
		}
	}

	+button {
		margin-left: 5px;
	}
}

small {
	font-size: 0.9rem;
}

.adaSign {
	padding-right: 3px;

	&.small {
		padding-right: 1px;
	}
}

.card, .tile.is-child {
	border-radius: $borderRadius;
	background-color: $white-bis;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

	&.has-light-shadow {
		box-shadow: 0 1px 3px rgba(0,0,0,0.08), 0 1px 1px rgba(0,0,0,0.16);
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.has-width-80 {
	width: 80%;
}

.has-width-100 {
	width: 100%;
}

.dropdown-item {
	user-select: none;
}
