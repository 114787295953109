@import '../../../../style/variables';

.youtubeUnit {
	background-color: #181818;
	padding: 64px 32px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media screen and (max-width: $tablet) {
		padding: 32px 8px 32px 8px;
	}

	h1 {
		color: $grey-light;
		font-size: 3rem;
		text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
		width: 100%;
		text-align: center;
		margin: 0;
		line-height: 60px;

		@media screen and (max-width: $tablet) {
			font-size: 2rem;
		}
	}

	.learnWithUsWrapper {
		cursor: pointer;
	}

	.ytChannelLogo {
		border-radius: 50%;
		max-width: 200px;
		max-height: 200px;

		@media screen and (max-width: $tablet) {
			font-size: 2rem;

			max-width: 80px;
			max-height: 80px;
		}
	}

	.ytSubscribers {
		color: $grey-light;
		position: relative;
		top: -10px;
	}

	.youtubeChannel {
		padding: 24px;
		border-radius: $borderRadius;
		width: 100%;

		@media screen and (max-width: $tablet) {
			width: 100%;
		}

		iframe, img {
			border-radius: $borderRadius;
		}

		p {
			color: $white-bis;
		}

		button {
			background-color: $grey-darker;
			border: 1px solid $grey;

			max-width: 250px;
		}

		.videoTileColumn {
			min-width: 240px;
			max-width: 240px;
		}

		.youtubeVideoTile {
			img {
				width: 210px;
			}

			.videoTitle {
				font-size: 0.95rem;
				font-weight: bold;

				a, a:hover, a:active, a:visited {
					color: $grey-light;
					display: -webkit-box;
					line-clamp: 2;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					transition: color 150ms ease-in-out;
				}

				a:hover {
					color: $white;
				}
			}

			.videoStats {
				padding-top: 16px;
				color: $grey-light;
				font-size: 0.9rem;
			}
		}
	}
}
