@import '../../../style/variables';

.Header {
  width: 100%;
  height: $headerHeight;
  padding: 12px 12px 14px 12px;
  transition: all 200ms ease-in-out;
  background-color: $headerColor;
  box-shadow: 0 3px 2px 2px rgba(0, 0, 0, 0.15);
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1580px) {
    display: block;
    height: auto;
  }

  &.shrunk {
    height: 50px;
  }

  .logoColumns {
    width: 100%;
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 0;
    max-width: 500px;

    .logoIconContainer {
      display: flex;
      align-items: flex-end;

      svg.logoIcon {
        width: auto;
        max-height: 60px;
        color: $woodlandYellow;
      }
    }

    .logoTextContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;

      svg.logoText {
        max-width: 100%;
        height: auto;
        padding-left: 20px;
        padding-bottom: 6px;
        color: $white-ter;
      }

      svg.tagline {
        max-width: 100%;
        height: auto;
        padding-left: 20px;
        color: $white-ter;
      }
    }
  }

  .navContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    .headerNav {
      height: 100%;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-wrap: wrap;

        li {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0 16px;
          font-size: 1rem;
          font-family: Lato, sans-serif;
          font-weight: 700;
          color: $woodlandYellow;
          letter-spacing: 2px;

          a {
            color: inherit;
            transition: color 100ms ease-in-out;
            position: relative;

            &:hover {
              color: darken($woodlandYellow, 10%);
            }
          }
        }
      }
    }

    @media screen and (max-width: 1580px) {
      margin-top: 12px;
      position: relative;
      align-items: flex-start;
      width: 100%;

      .headerNav {
        width: 100%;

        ul {
          justify-content: flex-start;
        }
      }
    }
  }


}

.namiDelegateContainer {
  display: flex;

  .delegateButton {
    color: white;
    background-color: #299e98;
    font-size: 14px;
    padding: 14px;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    margin-left: 16px;
  }

  .delegateButton:hover {
    background-color: #d96016;
  }
}
