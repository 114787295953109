@import '../../../style/variables';

.autocomplete-wrapper {
	display: block;
	position: relative;
	width: 100%;
}

.autocomplete {
	.dropdown-content {
		max-height: 300px;
		overflow-y: auto;
	}

	input.is-locked {
		background-color: $grey-lighter;
		cursor: default;
		user-select: none;
		-moz-user-select: none;
		pointer-events: none;
	}

	.clearButton {
		cursor: pointer;
		position: relative;
		left: -1px;
	}
}
