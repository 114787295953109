@import '../../../style/variables';
@import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";

.payoutCalculator {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 80%;

	@media screen and (max-width: $tablet) {
		max-width: 100%;
	}

	h2 {
		margin-top: 0;
	}

	.informationSection {
		.disclaimer {
			border-left: 3px solid $orange;
			background-color: $grey-lightest;
			padding: 4px 4px 4px 8px;
			border-radius: $borderRadius;
			font-size: 0.9rem;
		}

		.dateInput {
			background-color: $grey-lightest;
			padding: 12px;
			border-radius: $borderRadius;
			margin-top: 24px;

			h4 {
				font-size: 1rem;
				line-height: 14px;
				font-weight: bold;
			}

			small {
				display: block;
				margin: 4px 0 6px 0;
			}

			.react-datepicker-wrapper {
				display: inline-block;
			}

			button {
				display: inline-block;
			}
		}
	}

	.results {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 32px;

		.placeholder {
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-top: 128px;
			opacity: 0.75;


			@media screen and (max-width: $tablet) {
				margin-top: 32px;
			}

			.placeholderIcon {
				text-align: center;
				font-size: 5rem;
				margin-bottom: 32px;

				&.loading {
					.fa-spinner {
						animation: rotation 2s infinite linear;
					}
				}
			}

			.placeholderText {
				font-size: 1.5rem;
				user-select: none;
				-moz-user-select: none;
				-webkit-user-select: none;
			}
		}

		.resultRow {
			width: 100%;
			display: flex;
			flex-direction: row;
			padding-left: 24px;

			&:nth-child(odd) {
				.resultContent {
					background-color: $white-ter;
				}
			}

			.epochNumber {
				width: 120px;
				min-width: 80px;
				display: flex;
				align-items: center;
				font-size: 1rem;
				font-weight: bold;
				font-family: 'Noto Sans', sans-serif;
				font-variant: all-small-caps;
				color: #5A6173;
			}

			.resultContent {
				width: 100%;
				height: 100px;
				border-left: none;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 24px;
				padding-top: 4px;

				.epochHeader {
					width: 100%;
					font-size: 0.9rem;
				}

				p {
					font-size: 1rem;
					opacity: 0.9;
				}

				&:before {
					content: '';
					display: block;
					width: 9px;
					height: 9px;
					border-radius: 50%;
					position: absolute;
					top: 47px;
					left: -6px;
					border: 2px solid;
					background-color: $white;
				}
			}

			&:nth-child(1) .resultContent {
				border-left: 3px solid $grey-dark;
				border-top-left-radius: $borderRadius;

				.resultContent {
					border-top-right-radius: $borderRadius;
				}

				&:before {
					border-color: $grey-dark;
				}
			}

			&:nth-child(2) .resultContent {
				border-left: 3px solid $blue;

				&:before {
					border-color: $blue;
				}
			}

			&:nth-child(3) .resultContent {
				border-left: 3px solid $purple;

				&:before {
					border-color: $purple;
				}
			}

			&:nth-child(4) .resultContent {
				border-left: 3px solid $orange;

				&:before {
					border-color: $orange;
				}
			}

			&:nth-child(5) .resultContent {
				border-left: 3px solid $green;
				border-bottom-left-radius: $borderRadius;

				&:before {
					border-color: $green;
				}

				.resultContent {
					border-bottom-right-radius: $borderRadius;
				}

				p {
					font-weight: bold;
				}

				.fa-star {
					color: darken($yellow, 20%);
				}
			}
		}
	}
}
