@import '~bulma/sass/utilities/initial-variables';

$textColor: #444;

$backgroundColor: #7C96A6;
$poolIDBgColor: #7EA3A3;

$woodlandBrown: #454343;
$woodlandYellow: #FFE7C2;
$woodlandBlue: #7592a8;

$headerColor: #393737;
$headerHeight: 80px;


$heroHeight: 320px;
$borderRadius: 2px;
$pageMargin: 64px;

$phone: 500px;
