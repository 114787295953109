@import './style/variables.scss';
@import '~bulma/bulma';
@import './style/common';
@import './style/animations.scss';

:root {
	--page-margin: #{$pageMargin};
	overflow-y: hidden;

	@media screen and (max-width: $tablet) {
		--page-margin: 0px;
	}
}

.App {
	main {
		.mainContentWrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100vh;
		}
	}
}
