html {
	&.modal-open {
		overflow: hidden;

		body, #root, .App {
			overflow: hidden !important;
		}
	}
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16pt;
	font-family: Inter, sans-serif;
}
